import { parseResult, nonceDeprecationPool, applyQueryString, commonUrlBuilder, addCorruptRestApi, addCorruptRestApiLog, removeCorruptRestApi } from ".";
import deepMerge from "deepmerge";
import jsonFormData from "json-form-data";
import { ERouteHttpVerb } from "@devowl-wp/api";
const CONTENT_TYPE_JSON = "application/json;charset=utf-8"; // Fix: Return type of exported function has or is using name 'FormatOptions'
// from external module "/home/mg/vscode-workspace/devowl-wp/node_modules/@types/json-form-data/index" but cannot be named.
// type MultiPartOptions = Parameters<typeof jsonFormData>[1];

/**
 * Build and execute a specific REST query.
 *
 * @see urlBuilder
 * @returns Result of REST API
 * @throws
 */
async function commonRequest({
  location,
  options,
  request: routeRequest,
  params,
  settings = {},
  cookieValueAsParam,
  multipart = false,
  sendRestNonce = true
}) {
  const namespace = location.namespace || options.restNamespace;
  const url = commonUrlBuilder({
    location,
    params,
    nonce: false,
    options,
    cookieValueAsParam
  }); // Use global parameter (see https://developer.wordpress.org/rest-api/using-the-rest-api/global-parameters/)

  if (["wp-json/", "rest_route="].filter(s => url.indexOf(s) > -1).length > 0 && location.method && location.method !== ERouteHttpVerb.GET) {
    settings.method = ERouteHttpVerb.POST;
  } else {
    settings.method = location.method || ERouteHttpVerb.GET;
  } // Request with GET/HEAD method cannot have body


  const apiUrl = new URL(url, window.location.href);
  const allowBody = ["HEAD", "GET"].indexOf(settings.method) === -1;

  if (!allowBody && routeRequest) {
    applyQueryString(apiUrl, [routeRequest], true);
  }

  const apiUrlBuilt = apiUrl.toString(); // Determine body

  let body;

  if (allowBody) {
    if (multipart) {
      // Let's create a multipart request...
      body = jsonFormData(routeRequest, typeof multipart === "boolean" ? {} : multipart);
    } else {
      // It is a usual JSON request, we do not need to send a multipart request
      body = JSON.stringify(routeRequest);
    }
  } // Do the request


  const restNonce = await nonceDeprecationPool(options.restNonce);
  const hasRestNonce = typeof restNonce !== "undefined";
  const init = deepMerge.all([settings, {
    headers: { ...(typeof body === "string" ? {
        "Content-Type": CONTENT_TYPE_JSON
      } : {}),
      ...(hasRestNonce && sendRestNonce ? {
        "X-WP-Nonce": restNonce
      } : {}),
      Accept: "application/json, */*;q=0.1"
    }
  }]);
  init.body = body; // Do not make body merge-able

  let result; // Detect page hide of browser which can lead to cancelled requests which throw an error.
  // In this case, we should not show the notice (see CU-33tce0y).

  let pageUnload = false;

  const pageUnloadListener = () => {
    pageUnload = true;
  };

  window.addEventListener("pagehide", pageUnloadListener);
  window.addEventListener("beforeunload", pageUnloadListener);
  const start = new Date().getTime();
  let ms;

  try {
    result = await window.fetch(apiUrlBuilt, init);
    ms = new Date().getTime() - start;
    removeCorruptRestApi(namespace);
  } catch (e) {
    // window.fetch does not throw by default, so there must be an error with the network or Ad-blocker
    ms = new Date().getTime() - start;

    if (!pageUnload) {
      addCorruptRestApiLog({
        method: location.method,
        route: apiUrl.pathname,
        ms,
        response: "".concat(e)
      });
      addCorruptRestApi(settings, namespace);
    }

    console.error(e);
    throw e;
  } finally {
    window.removeEventListener("pagehide", pageUnloadListener);
    window.removeEventListener("beforeunload", pageUnloadListener);
  } // `window.fetch` does not throw an error if the server response an error code.


  if (!result.ok) {
    let responseJSON = undefined;
    let replay = false;

    try {
      responseJSON = await parseResult(apiUrlBuilt, result, location.method); // wordpress.com private site compatibility

      /* istanbul ignore if */

      if (responseJSON.code === "private_site" && result.status === 403 && hasRestNonce && !sendRestNonce) {
        replay = true;
      } // Refresh nonce automatically

      /* istanbul ignore if */


      if (responseJSON.code === "rest_cookie_invalid_nonce" && hasRestNonce) {
        const {
          restRecreateNonceEndpoint
        } = options;

        try {
          await nonceDeprecationPool(restNonce, restRecreateNonceEndpoint);
          replay = true;
        } catch (e) {// Silence is golden.
        }
      }
    } catch (e) {// Silence is golden.
    }

    if (replay) {
      return await commonRequest({
        location,
        options,
        multipart,
        params,
        request: routeRequest,
        sendRestNonce: true,
        settings
      });
    }

    addCorruptRestApiLog({
      method: location.method,
      route: apiUrl.pathname,
      ms,
      response: JSON.stringify(responseJSON)
    });
    addCorruptRestApi(settings);
    const resultAny = result;
    resultAny.responseJSON = responseJSON;
    throw resultAny;
  }

  return parseResult(apiUrlBuilt, result, location.method);
}

export { commonRequest };